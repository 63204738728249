@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 229, 100%, 97%;
    --foreground: 226, 94%, 32%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: #05299E;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --accent-alt: 220 50% 25.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-background text-foreground font-opensans;
  }

  html,
  body,
  #root {
    @apply full-screen;
  }

  html:has(> body > #root > .hide-main-scrollbar) {
    @apply hide-scrollbar;
  }

  .full-screen {
    min-height: 100vh;
    min-height: 100dvh;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer utilities {
  .h-dvh {
    height: 100vh;
    height: 100dvh;
  }

  .min-h-dvh {
    min-height: 100vh;
    min-height: 100dvh;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .text-shadow {
    text-shadow: 4px 4px 8px black;
  }

  .gradient-background {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: min(90vh, 90vw);
    width: min(90vh, 90vw);
    aspect-ratio: 1/1;
    background: linear-gradient(
      300deg,
      skyblue,
      deepskyblue,
      darkviolet,
      deepskyblue,
      skyblue
    );
    background-size: 180% 180%;
    border-radius: 100%;
    animation: gradient-animation 12s linear infinite;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 50% 50%;
  }

  25% {
    background-position: 0% 100%;
  }

  50% {
    background-position: 100% 0%;
  }

  75% {
    background-position: 75% 25%;
  }

  100% {
    background-position: 50% 50%;
  }
}
